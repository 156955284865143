<template>
  <div class="products-container">
  </div>
</template>
<script>
export default {
  components: {
  },
  props: {
    value: {

    }
  },
  computed: {
  }
}
</script>
<style scoped lang="scss">
.products-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
</style>
